import React from "react";
import config from "../config";

export default function(props) {
  return (
    (props.name && props.date && (
      <div className="color-dark text-left font-m pt-3 pb-4">
        {/* <Row> */}
        {/* <Col className="middle font-s"> */}{" "}
        <div style={{ float: "left" }}>
          <img
            width="64"
            src={`https://${config.URL}/images/${props.name}.png`}
            className="pr-2 pl-0"
            alt={`${props.name[0]}`}
          />
        </div>
        <div className="font-s fg-col-mid">
          Posted <span className="">{props.date}</span> <br />
          by {props.name}
        </div>
        {/* </Col> */}
        {/* </Row> */}
      </div>
    )) || <div></div>
  );
}
