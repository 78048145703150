export default {
  Auth: {
    identityPoolId: "eu-west-1:fc0f33db-0ab5-46c2-8beb-c7c769e7016d",
    region: "eu-west-1",
    userPoolId: "eu-west-1_MG8A6PBDX",
    userPoolWebClientId: "39g0v0rmogeqibi02p0bf5kpj8",
    mandatorySignIn: false
  },
  API: {
    endpoints: [
      {
        name: "backend",
        endpoint: "https://kv0snmsgob.execute-api.eu-west-1.amazonaws.com/api",
        region: "eu-west-1"
      }
    ]
  },
  Storage: {
    AWSS3: {
        bucket: 'devlab0.com-vjifixct',
        region: "eu-west-1"
    }
  },
  API_Key: 'QnTYw3kYJh4TG3nTziS1d5Sh4iUGS9SO9zHtu2nS',
  URL: 'devlab0.com'
};
