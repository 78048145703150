import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Container, Row, Col } from "reactstrap";
import ReactMarkdown from 'react-markdown';
import Prism from 'prismjs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// export { default as coy } from './coy';
// export { default as dark } from './dark';
// export { default as funky } from './funky';
// export { default as okaidia } from './okaidia';
// export { default as solarizedlight } from './solarizedlight';
// export { default as tomorrow } from './tomorrow';
// export { default as twilight } from './twilight';
// export { default as prism } from './prism';
// export { default as atomDark } from './atom-dark';
// export { default as base16AteliersulphurpoolLight } from './base16-ateliersulphurpool.light';
// export { default as cb } from './cb';
// export { default as darcula } from './darcula';
// export { default as duotoneDark } from './duotone-dark';
// export { default as duotoneEarth } from './duotone-earth';
// export { default as duotoneForest } from './duotone-forest';
// export { default as duotoneLight } from './duotone-light';
// export { default as duotoneSea } from './duotone-sea';
// export { default as duotoneSpace } from './duotone-space';
// export { default as ghcolors } from './ghcolors';
// export { default as hopscotch } from './hopscotch';
// export { default as pojoaque } from './pojoaque';
// export { default as vs } from './vs';
// export { default as xonokai } from './xonokai';
//tomorrow
import { twilight as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import CodeBlock from '../CodeBlock';
import 'prismjs/components/prism-jsx';
// import 'prismjs/themes/prism-coy.css';

// function CodeBlock(props) {
//   var html = Prism.highlight(props.literal, Prism.languages[props.language]);
//   var cls = 'language-' + props.language;

//   return (
//     <pre className={cls}>
//       <code dangerouslySetInnerHTML={{ __html: html }} className={cls} />
//     </pre>
//   );
// }

// var lang = /\blang(?:uage)?-([\w-]+)\b/i;

// function getLanguage(element) {
//   while (element && !lang.test(element.className)) {
//     element = element.parentNode;
//   }
//   if (element) {
//     return (element.className.match(lang) || [, 'none'])[1].toLowerCase();
//   }
//   return 'none';
// }

class CodeBlock extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string,
  };

  static defaultProps = {
    language: null,
  };

  render() {
    let { language, value } = this.props;
    // language = getLanguage(value);
    return (
      <SyntaxHighlighter language={language} style={style}>
        {/* <div className="bg-col-dark">{value}</div> */}
        {value}
      </SyntaxHighlighter>
    );
  }
}

export default function Title(props) {
  return (
    <ReactMarkdown
      className="fg-col-dark"
      escapeHtml={false}
      source={props.value}
      renderers={{ code: CodeBlock }}
    />
  );
}
