import React from "react";
import Title from "./Header/Title";
import SubTitle from "./Header/SubTitle";
import Image from "./Header/Image";
import PosterInfo from "../PosterInfo";

export default function Header(props) {
  return (
    <>
      <Title value={props.title} state={props.state} />
      <SubTitle value={props.subtitle} />
      <PosterInfo name={props.poster} date={props.date} />
      <Image url={props.header["url"]} caption={props.header["caption"]} />
    </>
  );
}
