import React, { useState, useContext, useEffect } from 'react';
import {
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Container,
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap';
import { navigate } from 'hookrouter';
import Attachments from './Attachments';
import { Delete, Put, MakeStub } from '../../../_api';
import {
  AlertCtx,
  UserCtx,
  PostListRefreshCtx,
} from '../../../context/ContextWrapper';

export default function EditForm(props) {
  const user = useContext(UserCtx)[0];
  let setAlerts = useContext(AlertCtx)[1];
  const [buttonDisabled, setButtonDisabled] = useState({});
  const [refreshPostList, setRefreshPostList] = useContext(PostListRefreshCtx);
  const [post, setPost] = useState({});
  const [setImages] = useState({});

  useEffect(() => {
    setPost(props.post);
  }, [props]);

  useEffect(() => {
    props.new && setPost({ Title: '', SubTitle: '', Body: '' });
  }, [props.new]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const del = date => {
    Delete('/post?postdate=' + date).then(() => {
      setAlerts([
        {
          id: new Date().getTime(),
          type: 'info',
          headline: `Post deleted!`,
          message: `Goodbye, ${post.Stub}`,
        },
      ]);
      // setLoading(true);
      setTimeout(() => {
        navigate('/');
        setRefreshPostList(!refreshPostList);
        window.location.reload();
        // setLoading(false);
      }, 1000);
    });
  };

  const save = State => {
    const Stub = post.Stub ? post.Stub : MakeStub(post.Title);
    setButtonDisabled({ ...buttonDisabled, save: true });
    if (!user) {
      setAlerts([
        {
          id: new Date().getTime(),
          type: 'danger',
          headline: `Failed to save!`,
          message: "You're not logged in!",
        },
      ]);
      return;
    }

    Put('/post', {
      ...post,
      State,
      Stub,
    })
      .then(res => {
        props.setPost({ ...post, Stub });
        props.setActiveTab('article');
        navigate('/posts/' + Stub, true);
        setRefreshPostList(!refreshPostList);
        window.scrollTo(0, 0);
      })
      .catch(x => {
        setAlerts([
          {
            id: new Date().getTime(),
            type: 'danger',
            headline: `Failed to save!`,
            message: `Something went wrong`,
          },
        ]);
        setButtonDisabled({ ...buttonDisabled, save: false });
      });
  };

  const SaveButton = props => {
    return (
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <DropdownToggle
          color={post && post.State === 'Published' ? 'success' : 'warning'}
          caret
        >
          Save
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => save('Draft')}>Draft</DropdownItem>
          <DropdownItem onClick={() => save('Published')}>
            Published
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  return (
    <Container className="p-2">
      <Form className="pt-3">
        <FormGroup>
          <Row>
            <Col md={2} className="font-weight-bold middle">
              <Label>Title</Label>
            </Col>
            <Col>
              <Input
                className="pl-2"
                type="textarea"
                rows={1}
                value={post && post.Title}
                onChange={e => {
                  setPost({ ...post, Title: e.target.value });
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={2} className="font-weight-bold">
              <Label>Subheading</Label>
            </Col>
            <Col>
              <Input
                className="p-2"
                type="textarea"
                rows={3}
                value={post && post.SubTitle}
                onChange={e => {
                  setPost({ ...post, SubTitle: e.target.value });
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={2} className="font-weight-bold">
              <Label>Body</Label>
            </Col>
            <Col>
              <Input
                id="post-body"
                type="textarea"
                className="p-2"
                rows={10}
                value={post && post.Body}
                onChange={e => {
                  setPost({ ...post, Body: e.target.value });
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Attachments
            images={post.Images && JSON.parse(post.Images)}
            setImages={setImages}
            post={props.post}
          />
          {/* </div> */}
          {/* <div className="w-100 text-center"> */}
          {/* IMAGES being passed as props in Attachments: {JSON.stringify(images)} */}
          {/* <FileUpload
              images={post.Images && JSON.parse(post.Images)}
              setImages={setImages}
              post={props.post}
              Stub={props.post.Stub}
            ></FileUpload> */}
        </FormGroup>
        <FormGroup>
          <div className="w-100 text-center"></div>
        </FormGroup>
        <FormGroup>
          <div className="w-100 text-center">
            <ButtonGroup>
              <Button
                color="danger"
                onClick={() => {
                  del(props.post.PostDate);
                }}
              >
                Delete
              </Button>
              <SaveButton></SaveButton>
            </ButtonGroup>
          </div>
        </FormGroup>
      </Form>
    </Container>
  );
}
