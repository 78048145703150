import { withAuthenticator } from "aws-amplify-react";
import Amplify from "aws-amplify";
import config from "../config";

Amplify.configure(config);

const Profile = () => {
  return "There will be a profile here some day";
};

export default withAuthenticator(Profile, false);
