// import { AlertList } from "react-bs-notifier";
import React from 'react';
// import { AlertCtx } from '../context/ContextWrapper';

function AlertPanel() {
  // const [alerts, setAlerts] = useContext(AlertCtx);

  // return (
  //   <AlertList
  //     position="bottom-right"
  //     alerts={alerts || []}
  //     timeout={2000}
  //     dismissTitle="Close"
  //     onDismiss={() => setAlerts([])}
  //   />
  // );
  // todo: replace alerting
  return <></>;
}

export default AlertPanel;
