import Amplify, { Auth } from 'aws-amplify';
import { navigate } from 'hookrouter';
import { useContext } from 'react';
import { UserCtx } from '../context/ContextWrapper';
import config from '../config';
Amplify.configure(config);

export default function LogOut() {
  const setUser = useContext(UserCtx)[1];
  Auth.signOut()
    .then(() => {
      setUser('');
      navigate('/');
    })
    .catch(e => {
      // todo: log error
    });
  return 'Logged Out!';
}
