import { Auth } from 'aws-amplify';
import React, { useEffect, useState, createContext } from 'react';

const UserCtx = createContext(['', () => {}]);
const AlertCtx = createContext(['', () => {}]);
const LoadingCtx = createContext(['', () => {}]);
const PostListRefreshCtx = createContext([false, () => {}]);

export { UserCtx, AlertCtx, LoadingCtx, PostListRefreshCtx };

export default function ContextWrapper(props) {
  const [user, setUser] = useState();
  const [alerts, setAlerts] = useState();
  const [loading, setLoading] = useState(false);
  const [refreshPostList, setRefreshPostList] = useState(false);

  useEffect(() => {
    async function auth() {
      await Auth.currentAuthenticatedUser()
        .then(u => {
          setUser(u.username);
        })
        .catch(e => {
          // todo: log error
        });
    }
    auth();
  }, [user]);

  return (
    <PostListRefreshCtx.Provider value={[refreshPostList, setRefreshPostList]}>
      <LoadingCtx.Provider value={[loading, setLoading]}>
        <UserCtx.Provider value={[user, setUser]}>
          <AlertCtx.Provider value={[alerts, setAlerts]}>
            {props.children}
          </AlertCtx.Provider>
        </UserCtx.Provider>
      </LoadingCtx.Provider>
    </PostListRefreshCtx.Provider>
  );
}
