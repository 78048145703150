import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { LoadingCtx } from "../context/ContextWrapper";
import "./css/LoadScreen.css";

export default function LoadScreen() {
  const loading = useContext(LoadingCtx)[0] ? "visible" : "hidden";
  return (
    <>
      <div id="overlay" style={{ visibility: loading }}>
        <Spinner />
      </div>
    </>
  );
}
