import Amplify, { API, Storage } from 'aws-amplify';
import config from './config';
Amplify.configure(config);

const Alert = (type, headline, message) => {
  return {
    id: new Date().getTime(),
    type,
    headline,
    message,
  };
};

const MakeStub = title => {
  return (
    title
      .replace(/[^\w]/gi, '-')
      // .replace(/[\s]/gi, '-')
      .replace(/[-]+/gi, '-')
      .toLowerCase()
  );
};

const Upload = async (name, content, post, extension, contentType) => {
  if (!post.Images || post.Images.indexOf(name) === -1) {
    await Storage.put('images/' + name, content, {
      contentType,
    })
      .then(async result => {
        if (!post.Images) post.Images = JSON.stringify({});
        post.Images = JSON.stringify(
          name in JSON.parse(post.Images)
            ? post.Images
            : { ...JSON.parse(post.Images), [name]: '' },
        );
        await Put('/post', {
          ...post,
        });
      })
      .catch(e => {
        // todo: log error
      });
  }
};

const Put = (path, body) => {
  // todo: log activity
  const payload = {
    headers: {
      'x-api-key': config.API_Key,
    },
    body: {
      ...body,
    },
  };
  return API.put('backend', path, payload);
};

const Delete = (path, body) => {
  //todo: log activity
  const payload = {
    headers: {
      'x-api-key': config.API_Key,
    },
    body: {
      ...body,
    },
  };
  return API.del('backend', path, payload);
};

const Read = path => {
  // todo: log activity
  const payload = {
    headers: {
      'x-api-key': config.API_Key,
    },
    body: null,
  };
  const result = API.get('backend', path, payload);
  return result;
};

export { Alert, Upload, Read, Put, MakeStub, Delete };
