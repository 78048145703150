import './App.css';
import React from 'react';
import Menu from './components/Menu';
import { Container } from 'reactstrap';
import { useRoutes } from 'hookrouter';
import LogOut from './components/LogOut';
import Profile from './components/Profile';
import PostList from './components/PostList';
// import withEditor from "./components/hocs/WithEditor";
import LoadScreen from './components/LoadScreen';
import AlertPanel from './components/AlertPanel';
import BlogPost from './components/BlogPost/BlogPost';
import ContextWrapper from './context/ContextWrapper';
// import Prism from 'prismjs';

const FourOhFour = props => {
  return <>404</>;
};

// const Post = withEditor(BlogPost);

const routes = {
  '/': () => <BlogPost stub={'hello-world'} />,
  '/404': () => <FourOhFour />,
  '/login': () => <Profile />,
  '/logout': () => <LogOut />,
  '/new': () => <BlogPost new={true} />,
  '/edit/:stub': ({ stub }) => <BlogPost stub={stub} edit={true} />,
  '/posts/:stub': ({ stub }) => <BlogPost stub={stub} />,
};

export default function App() {
  // window.LOG_LEVEL = "DEBUG";
  const routeResult = useRoutes(routes);
  return (
    <ContextWrapper>
      <LoadScreen />
      <div className="w-100 p-0">
        <AlertPanel />
        <Menu />
        <Container
          className="font-m p-3"
          style={{
            maxWidth: 800,
          }}
          id="content"
        >
          {routeResult}
        </Container>
        <div className="w-100 p-0 m-0">
          <PostList />
        </div>
      </div>
    </ContextWrapper>
  );
}
