import React from "react";
import { Badge } from "reactstrap";

export default function Title(props) {
  return (
    <div className="text-left font-xl font-serif font-weight-bold fg-col-dark">
      {props.value}{" "}
      {props.state === "Draft" ? <Badge color="secondary">Draft</Badge> : ""}
    </div>
  );
}
