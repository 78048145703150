import React, { useEffect, useContext, useState } from 'react';
import Body from './Body';
import Header from './Header';
import { AlertCtx, LoadingCtx } from '../../context/ContextWrapper';
import WithEditor from '../hocs/WithEditor';
import { Container, Row, Col } from 'reactstrap';
import { Read, Alert } from '../../_api';

export default function BlogPost(props) {
  const [post, setPost] = useState({});
  const setLoading = useContext(LoadingCtx)[1];
  const setAlerts = useContext(AlertCtx)[1];

  useEffect(() => {
    const getPost = stub => {
      setLoading(true);
      Read(stub ? '/post?stub=' + stub : '/post?stub=first-post')
        .then(data => {
          setPost(data.message);
          setLoading(false);
        })
        .catch(x => {
          // navigate("/" + x.response.status);
          // setAlerts([
          //   Alert(
          //     'danger',
          //     `${x.response.status} error`,
          //     `${JSON.stringify(x.message)}`,
          //   ),
          // ]);
          console.log('ERR:', x);
          setLoading(false);
        });
    };
    if (!props.new) {
      getPost(props.stub);
    } else {
      setPost({});
    }
  }, [props.post, props.new, props.stub, setLoading, setAlerts]);

  return (
    <>
      <WithEditor
        post={post}
        setPost={setPost}
        edit={props.edit}
        new={props.new}
      >
        <Container>
          <Row>
            <Col md={12} className="text-center p-0 pt-3">
              <Header
                state={post.State}
                title={post.Title}
                subtitle={post.SubTitle}
                poster={post.Poster}
                date={post.PostDate && post.PostDate.substring(0, 10)}
                header={{
                  url: post.Header,
                  caption: post.Images && JSON.parse(post.Images)[post.Header],
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-left p-0 pt-4 font-m">
              <div id="article">
                <Body value={post.Body} />
              </div>
            </Col>
          </Row>
        </Container>
      </WithEditor>
    </>
  );
}
