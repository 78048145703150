import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Progress } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { Upload } from '../../../_api';
// import config from "../../../config";

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
};

export default function FileUpload(props) {
  const [files, setFiles] = useState([]);
  const [remaining, setRemaining] = useState();
  const [images, setImages] = useState({});
  // const [post, setPost] = useState({});

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }),
      );
    },
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt={file.preview} />
      </div>
    </div>
  ));

  // useEffect(() => {

  // },[remaining])

  const upload = () => {
    setRemaining(files.length);
    let r = files.length;
    let i = (props.images && JSON.parse(JSON.stringify(props.images))) || {};
    return files.map(async file => {
      const f = [];
      const reader = new FileReader();
      reader.onabort = e => {
        // todo: log error
      };
      reader.onerror = e => {
        // todo: log error
      };
      reader.onload = async e => {
        const curFile = f.pop();
        const binaryStr = reader.result;
        await Upload(
          `${props.Stub}/${curFile.name}`,
          binaryStr,
          props.post,
          curFile.name.split('.').pop(),
          curFile.type,
        );
        r--;
        i = { ...i, [curFile.name]: '' };
        setImages(i);
        setRemaining(r);
        if (r === 0) {
          props.setImages(images);
          setFiles([]);
        }
      };
      f.push({ name: file.name, type: file.type });
      return reader.readAsArrayBuffer(file);
    });
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <>
      <div
        style={{
          flexWrap: 'nowrap',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        className="p-0 m-0 w-100 text-center"
      >
        {thumbs}
      </div>
      <div className="pt-3 w-100 text-center">
        {(files.length > 0 && (
          <ButtonGroup>
            <Button color="secondary" onClick={() => setFiles([])}>
              Cancel
            </Button>
            <Button color="primary" onClick={upload}>
              Upload {files.length} images
            </Button>
          </ButtonGroup>
        )) || (
          <div
            {...getRootProps({
              className: 'dropzone text-center w-100 cursor-pointer',
            })}
          >
            <input {...getInputProps()} />
            <Button onClick={() => {}}>Attach files</Button>
            <br />
          </div>
        )}
      </div>
      <br />
      {remaining > 0 && (
        <Progress
          className="mt-3 mb-3"
          animated
          value={100 - (100 / files.length) * remaining}
        />
      )}
    </>
  );
}
