import {
  Button,
  Container,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import React, { useContext, useState } from "react";
import { navigate } from "hookrouter";
import { UserCtx } from "../context/ContextWrapper";

export default function Menu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const UserMenu = () => {
    const user = useContext(UserCtx)[0];

    if (user) {
      return (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
        >
          <DropdownToggle caret>{user}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                navigate("/new");
                window.scrollTo(0, 0);
              }}
            >
              New Post
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => navigate("/logout")}>
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      return (
        <NavItem>
          <Button onClick={() => navigate("/login")}>Log in</Button>
        </NavItem>
      );
    }
  };

  return (
    <Navbar className="navbar-dark navbar-expand-sm bg-col-dark" fixed="top">
      <Container style={{ padding: 0, maxWidth: 960 }}>
        <NavbarBrand href="#" onClick={() => navigate("/")}>
          <span className="fg-col-light">Devlab0 Blog</span>
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          {UserMenu()}
        </Nav>
      </Container>
    </Navbar>
  );
}
