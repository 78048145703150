import {
  Card,
  Badge,
  CardDeck,
  CardTitle,
  CardBody,
  CardSubtitle,
  CardText,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import React, { useState, useEffect, useContext } from 'react';
import Amplify from 'aws-amplify';
import { Read, Alert } from '../_api';
import { navigate } from 'hookrouter';
import { BrowserView, MobileView } from 'react-device-detect';
import {} from '../context/ContextWrapper';
import {
  AlertCtx,
  LoadingCtx,
  PostListRefreshCtx,
} from '../context/ContextWrapper';
import config from '../config';
import WithPanelContainer from './hocs/WithPanelContainer';
import './css/PostList.css';

Amplify.configure(config);

const PostList = props => {
  const setLoading = useContext(LoadingCtx)[1];
  const refresh = useContext(PostListRefreshCtx)[0];
  const setAlerts = useContext(AlertCtx)[1];
  const [posts, setPosts] = useState([]);

  const goTo = path => {
    setLoading(true);
    document.getElementById('content').style.visibility = 'hidden';
    navigate(path);
    document.getElementById('content').style.visibility = 'visible';
    window.scrollTo(0, 0);
    setLoading(false);
  };

  useEffect(() => {
    Read('')
      .then(data => {
        setPosts(data.message.Items);
      })
      .catch(x => {
        setAlerts([
          Alert(
            'danger',
            `${x.response.status} error`,
            `${JSON.stringify(x.message)}`,
          ),
        ]);
      });
  }, [setAlerts, refresh]);

  const CardRow = props => {
    const view = props.view ? props.view : 'browser';

    return (
      <CardDeck className="p-0 m-0 ml-1 mr-1 mb-2">
        {props.posts.map(post => (
          <Card
            key={`card-${post.Stub}`}
            className="cursor-pointer p-0 mt-2 ml-1 mr-1 shadow"
            onClick={() => goTo('/posts/' + post.Stub)}
            style={{ opacity: post.State === 'Published' ? '1' : '0.5' }}
          >
            <div key={`div-${post.Stub}`} className="middle center">
              {/* {post.Header ? (
                <CardImg
                  style={{ maxWidth: 128, float: "left" }}
                  className="ml-3 rounded"
                  src={`https://${config.URL}/images/thumbs/${post.Header}`}
                  alt=""
                />
              ) : (
                ""
              )} */}
              <CardBody>
                <CardTitle className={`${view}-card-title`}>
                  {post.Title}{' '}
                  {post.State === 'Draft' ? (
                    <Badge color="secondary">Draft</Badge>
                  ) : (
                    ''
                  )}
                </CardTitle>
                <CardSubtitle
                  className={`${view}-card-subtitle font-xs fg-col-mid`}
                >
                  Posted on {post.PostDate.substring(0, 10)} by {post.Poster}
                </CardSubtitle>
                <CardText className="pt-2">{post.SubTitle}</CardText>
              </CardBody>
            </div>
          </Card>
        ))}
      </CardDeck>
    );
  };

  const BrowserPostlist = WithPanelContainer(() => (
    <div className="pt-0">
      <CardRow key="card-row-1" posts={posts.slice(0, 3)} />
      <CardRow key="card-row-2" posts={posts.slice(3, 6)} />
    </div>
  ));

  return (
    <>
      <BrowserView>
        <BrowserPostlist title="Recent posts" />
      </BrowserView>
      <MobileView>
        <div className="bg-col-dark p-1 m-0 font-m fg-col-light">
          Recent posts
        </div>
        <ListGroup flush={true} className="p-0 m-0 w-100">
          {posts.slice(0, 6).map(post => (
            <ListGroupItem
              key={post.Stub}
              onClick={() => goTo('/posts/' + post.Stub)}
            >
              <ListGroupItemHeading className="mobile-card-title">
                {post.Title}
              </ListGroupItemHeading>
              <ListGroupItemText>{post.SubTitle}</ListGroupItemText>
            </ListGroupItem>
          ))}
        </ListGroup>
      </MobileView>
    </>
  );
};

export default PostList;
