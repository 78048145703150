import React from "react";
import config from "../../../config";

export default function Image(props) {
  return props.url ? (
    <>
      <img
        width="100%"
        src={`https://${config.URL}/public/images/${props.url}`}
        alt={props.caption}
        // className="pad-top-l pad-bottom-l" {isMobile ? "mobile-post-header" : ""}
      />
      <br />
      <div className="text-center font-weight-lighter font-s fg-col-mid font-italic">
        {props.caption}
      </div>
    </>
  ) : (
    <div></div>
  );
}
