import React, { useState } from "react";
import WithPanelContainer from "../../hocs/WithPanelContainer";
import FileUpload from "./FileUpload";
import config from "../../../config";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Card,
  CardImg,
  CardBody
} from "reactstrap";

export default function Attachments(props) {
  const [post, setPost] = useState({});

  const insertImg = k => {
    // inserts an image into body of post at current cursor position
    const img = `<p align=center class="font-s fg-col-mid font-weight-light font-italic">
    <img style="max-width: 100%; max-height: 100%;" src="https://${config.URL}/public/images/${k}">
    <br />${props.images[k]}</p>`;
    const l = document.getElementById("post-body");
    const start = props.post.Body.substring(0, l.selectionStart);
    const end = props.post.Body.substring(l.selectionEnd);
    const lpad = start.endsWith("\n\n") ? "" : "\n\n";
    const rpad = end.startsWith("\n\n") ? "" : "\n\n";
    const cursorPos = `${start}${lpad}${img}${rpad}`.length;
    props.post.Body = l.value = `${start}${lpad}${img}${rpad}${end}`;
    setPost(post);
    l.setSelectionRange(cursorPos, cursorPos);
  };

  const handleCaptionChange = (e, k) => {
    props.images[k] = e.target.value;
    props.post.Images = JSON.stringify(props.images);
    setPost(post);
  };

  const AttachmentContent = () => (
    <>
      <Row
        style={{
          flexWrap: "nowrap",
          overflowX: "auto",
          whiteSpace: "nowrap"
        }}
        className="p-0 m-0"
      >
        {props.images &&
          Object.keys(props.images).map(k => {
            return (
              <Col className="pl-0 pr-3 m-0" key={k}>
                <Card style={{ width: 256 }}>
                  <p className="text-center pt-0">
                    <CardImg
                      className="pt-3"
                      src={`https://${config.URL}/images/thumbs/${k}`}
                      style={{ height: 128, width: 128 }}
                      onError={e => {
                        e.target.src = `https://${config.URL}/images/thumbs/${k}`;
                      }}
                      onClick={() => insertImg(k)}
                    />
                  </p>
                  <CardBody className="pt-0">
                    <InputGroup>
                      {" "}
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Input
                            addon
                            type="radio"
                            name="header"
                            aria-label=""
                            defaultChecked={props.post.Header === k}
                            onChange={e => {
                              props.post.Header = e.target.checked ? k : "";
                              setPost(post);
                            }}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        defaultValue={props.images[k]}
                        onChange={e => {
                          handleCaptionChange(e, k);
                        }}
                      />
                    </InputGroup>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>
      {/* <Row> */}
      {/* <div className="w-100 text-center"> */}
      <FileUpload
        images={props.post.Images && JSON.parse(props.post.Images)}
        setImages={props.setImages}
        post={props.post}
        Stub={props.post.Stub}
      ></FileUpload>
      {/* </div> */}
      {/* </Row> */}
    </>
  );

  const WrappedContainer = WithPanelContainer(AttachmentContent);
  return (
    <>
      <WrappedContainer title="Attachments" />
    </>
  );
}
