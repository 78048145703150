import React, { useContext, useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { navigate } from 'hookrouter';
import { UserCtx } from '../../context/ContextWrapper';
import EditForm from '../BlogPost/EditForm/EditForm';

const WithEditor = props => {
  const [activeTab, setActiveTab] = useState();
  const setPost = useState(props.post)[1];
  const user = useContext(UserCtx)[0];

  const tabClasses = tab => {
    return tab === activeTab
      ? 'font-weight-bold cursor-default'
      : 'cursor-pointer';
  };

  useEffect(() => {
    if (props.new) {
      props.setPost({});
      setPost({});
      setActiveTab('edit');
    } else if (props.edit) {
      setPost(props.post);
      setActiveTab('edit');
    } else {
      setPost(props.post);
      setActiveTab('article');
    }
  }, [props.new, props.edit, setPost, setActiveTab]);

  return user ? (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={tabClasses('article')}
            onClick={() => {
              navigate(`/posts/${props.post.Stub}`, true);
              setActiveTab('article');
            }}
          >
            Article
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabClasses('edit')}
            onClick={() => {
              navigate(`/edit/${props.post.Stub}`, true);
              setActiveTab('edit');
            }}
          >
            Edit
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="article">{props.children}</TabPane>
        <TabPane tabId="edit">
          <EditForm
            post={props.post}
            setPost={props.setPost}
            setActiveTab={setActiveTab}
            refresh={props.refresh}
            setRefresh={props.setRefresh}
            new={props.new}
          ></EditForm>
        </TabPane>
      </TabContent>
    </>
  ) : (
    props.children
  );
};

export default WithEditor;
