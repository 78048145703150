import React from "react";
import { Container } from "reactstrap";
// import { isMobile } from "react-device-detect";

const WithPanelContainer = WrappedComponent => props => {
  //   const marginTop = isMobile ? "0px" : "24px";
  //   const rounding = isMobile ? "" : "rounded";
  return (
    <Container className="pt-0 pb-0">
      <div className="rounded-top bg-col-dark p-1 m-0 pl-3 font-s fg-col-light">
        {props.title}
      </div>
      <div className="border p-3">
        <WrappedComponent {...props} />
      </div>
    </Container>
  );
};

export default WithPanelContainer;
